import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Switch, SwitchProps, Tooltip, Typography, useTheme } from "@mui/material"
import { useStateWithDeps } from "core/utils/tools"
import { ReactNode, useEffect, useState } from "react"

export interface RawSwitchProps extends Omit<SwitchProps, "onChange"> {
    nullable?: boolean
    error?: boolean
    helperText?: string
    missing?: boolean
    necessary?: boolean
    legend?: string | ReactNode
    leftText?: string | ReactNode
    rightText?: string | ReactNode
    midText?: string | ReactNode
    leftValue?: any
    rightValue?: any
    value?: any
    onChange?: (value: any, position: position) => any
    size?: "small" | "medium"
}

type position = "left" | "mid" | "right"

export function RawSwitch(props: RawSwitchProps) {
    const { value, onChange, leftText, rightText, leftValue = false, rightValue = true, missing, necessary, error, helperText, legend, nullable = false, size = "small", ...switchProps } = props

    const initialPosition = getPositionFromValue(value, leftValue, rightValue)
    const initialSide = switchProps.defaultChecked ? "right" : "left"

    const [currentPos, setCurrentPos] = useState<position>(initialPosition)
    const [lastSide, setLastSide] = useState<"left" | "right">(initialSide)

    //reset inital state on props change
    useEffect(() => {
        setCurrentPos(initialPosition)
    }, [initialPosition])

    useEffect(() => {
        setLastSide(initialSide)
    }, [initialSide])

    // alert(initialPosition + ' -> ' + currentPos)

    const handleChange = (selectedPos: position) => {
        const selectedValue = getValueFromPosition(selectedPos, leftValue, rightValue)
        setCurrentPos(selectedPos)
        onChange && onChange(selectedValue, selectedPos)
    }

    const handleClick = () => {
        const oldPos = currentPos
        let newPos: position
        if (oldPos === "left") newPos = nullable ? "mid" : "right"
        if (oldPos === "right") newPos = nullable ? "mid" : "left"
        if (oldPos === "mid") newPos = lastSide === "left" ? "right" : "left"

        if (newPos !== "mid") setLastSide(newPos)

        handleChange(newPos)
    }

    const theme = useTheme()

    const isMissing = missing ?? (necessary && currentPos === "mid")
    const alertColor = !error && isMissing ? theme.palette.warning.main : error ? theme.palette.error.main : null

    return (
        <FormControl>
            {legend && (
                <FormLabel
                    component="legend"
                    sx={{
                        fontSize: size,
                        "&, &.Mui-focused": {
                            ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                            ...(error && { color: (theme) => theme.palette.error.main }),
                        },
                    }}
                >
                    {!error && isMissing ? (
                        <Tooltip title="Nécessaire" placement="right" arrow>
                            <span>{`${legend} ⚠️`}</span>
                        </Tooltip>
                    ) : (
                        legend
                    )}
                </FormLabel>
            )}

            <Box display="flex" alignItems="center">
                {leftText && <Typography variant="caption">{leftText}</Typography>}
                <Switch
                    onClick={handleClick}
                    checked={currentPos === "right"}
                    {...switchProps}
                    {...{ size }}
                    sx={{
                        "& .MuiSwitch-root": { width: "67px" },
                        ...(currentPos === "mid" && {
                            "& .MuiSwitch-thumb": {
                                marginLeft: size === "small" ? "12px" : "15px",
                                marginTop: size === "small" ? "4px" : "5px",
                                color: "black",
                                height: size === "small" ? "7px" : "10px",
                                width: size === "small" ? "7px" : "10px",
                                ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                                ...(error && { color: (theme) => theme.palette.error.main }),
                            },
                        }),
                    }}
                />
                {rightText && <Typography variant="caption">{rightText}</Typography>}
            </Box>

            {helperText && (
                <FormHelperText
                    color={!error && isMissing ? "warning" : error ? "error" : null}
                    sx={{
                        marginLeft: "0px",
                        marginTop: "-2px",
                        "&, &.Mui-focused": {
                            ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                            ...(error && { color: (theme) => theme.palette.error.main }),
                        },
                    }}
                >
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}

function getPositionFromValue(value: any, leftValue: any, rightValue: any): position {
    if (value === leftValue) return "left"
    else if (value === rightValue) return "right"
    else return "mid"
}

function getValueFromPosition(position: position, leftValue: any, rightValue: any) {
    return { left: leftValue, right: rightValue }[position] ?? null
}
