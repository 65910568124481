import { AddBox as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material"
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import { rasmik, WeekDispo } from "core/services/rasmik"
import { dayName } from "core/utils/tools"
import { DateTime } from "luxon"

const rangeShape = rasmik.define
    .pickData(WeekDispo)
    .def({ required: ["dayId", "endTime", "startTime", "id"] })
    .shape()
type range = typeof rangeShape

interface props {
    ranges: Array<range>
    onEditClick: (row: range) => void
    onDeleteClick: (row: range) => void
    onAddClick: () => void
}
export const WeekTimeRangeList = (props: props) => {
    const { ranges = [], onAddClick, onDeleteClick, onEditClick } = props

    return (
        <List>
            {" "}
            {ranges.map((row, index) => {
                //row.index = index; (this is mutating or extanding state)
                return (
                    <ListItem key={index}>
                        <ListItemText>{`${dayName(row.dayId)} de ${DateTime.fromFormat(row.startTime, "HH:mm:ss").toFormat("H'h'mm").replace("h00", "h")} à ${DateTime.fromFormat(row.endTime, "HH:mm:ss").toFormat("H'h'mm").replace("h00", "h")} `}</ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments" onClick={() => onEditClick(row)} size="large">
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="comments" onClick={() => onDeleteClick(row)} size="large">
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
            <ListItem key={-1}>
                <ListItemText></ListItemText>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" size="large"></IconButton>
                    <IconButton edge="end" aria-label="comments" onClick={() => onAddClick()} size="large">
                        <AddIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
}
