import { useCallback } from "react"

import { useStateReinitialise } from "./useStateWithDeps"

export function useArray<T>(initialValue: T[], isEqual: (itemA: T, itemB: T) => boolean = (a, b) => a === b) {
    const hasChanged = (arrA: T[], arrB: T[]) => arrA?.length !== arrB?.length || arrA?.some((itemA, index) => !isEqual(itemA, arrB?.[index]!))

    const [array, setArray] = useStateReinitialise(initialValue, hasChanged)

    const push = useCallback(
        (item: T) => {
            setArray((arr) => {
                const copy = [...(arr ?? []), item]
                return copy
            })
        },
        [setArray]
    )

    const unshift = useCallback(
        (item: T) => {
            setArray((arr) => {
                const copy = [item, ...(arr ?? [])]
                return copy
            })
        },
        [setArray]
    )

    const set = useCallback(
        (items: T[]) => {
            setArray(items)
        },
        [setArray]
    )

    const remove = useCallback(
        (item: T) => {
            setArray((array) => {
                if (!array) return []

                const index = array.findIndex((x) => isEqual(x, item))

                const copy = [...array]

                copy.splice(index, 1)
                return copy
            })
        },
        [setArray, isEqual]
    )

    const toggle = useCallback(
        (item: T) => {
            setArray((array) => {
                if (!array) return []

                const index = array.findIndex((x) => isEqual(x, item))

                const copy = [...array]

                if (index === -1) {
                    copy.push(item)
                } else {
                    copy.splice(index, 1)
                }

                return copy
            })
        },
        [setArray, isEqual]
    )

    const replace = useCallback(
        (oldItem: T, newItem: T) => {
            setArray((arr) => {
                if (!arr) return []

                const index = array.findIndex((x) => isEqual(x, oldItem))

                const copy = [...arr]

                copy.splice(index, 1, newItem)
                return copy
            })
        },
        [setArray, array, isEqual]
    )

    const patch = useCallback(
        (oldItem: T, partialNwItem: Partial<T>) => {
            setArray((arr) => {
                if (!arr) return []

                const index = array.findIndex((x) => isEqual(x, oldItem))

                const copy = [...arr]

                const newItem = Object.assign({}, oldItem, partialNwItem)
                copy.splice(index, 1, newItem)
                return copy
            })
        },
        [setArray, array, isEqual]
    )

    return {
        array,
        arrayHelpers: {
            push,
            unshift,
            remove,
            replace,
            patch,
            set,
            toggle,
        },
    }
}
