import { EzModel } from "core/store/base"
import { action, computed, thunk } from "easy-peasy"
import { Action, Model, Property } from "easy-peasy-classes"

export const commonTextField = () => ({
    value: "",
    isDisabled: false,
    isError: false,
    // eslint-disable-next-line no-self-compare
    verifyFn: (value: string) => value === value,
    helperText: "",

    setValue: action((state, newValue) => {
        //@ts-ignore
        state.value = newValue
    }),
    setError: action((state, error) => {
        if (!error) {
            //@ts-ignore
            state.isError = false
            //@ts-ignore
            state.helperText = ""
        } else {
            //@ts-ignore
            state.isError = true
            //@ts-ignore
            state.helperText = error
        }
    }),

    verify: action((state) => {
        //@ts-ignore
        state.isError = state.verifyFn(state.value)
    }),

    handleChange: action((state, newValue) => {
        //@ts-ignore
        state.value = newValue
    }),
})

@Model
class commonTextFieldModel extends EzModel {
    @Property
    value: NonNullable<string> = ""

    @Property
    isDisabled: boolean = false

    @Property
    isError: boolean = false

    /** Small message to display under the field */
    @Property
    helperText: string = ""

    @Property
    inputMask: Array<string | RegExp> = null

    @Property
    label: string = ""

    @Property
    // eslint-disable-next-line no-self-compare
    verifyFn = (value: any) => value === value

    @Action
    setValue(newValue: string) {
        this.value = newValue || "" //TextField values should never be null
    }

    @Action
    setError(error: string) {
        if (!error) {
            this.isError = false
            this.helperText = ""
        } else {
            this.isError = true
            this.helperText = error
        }
    }

    @Action
    verify() {
        this.isError = this.verifyFn(this.value)
    }

    @Action
    handleChange(newValue: string) {
        this.value = newValue
    }
}
export { commonTextFieldModel }

/**
 handleChange: thunk((actions,newValue,{getState, getStoreState})=>{
        actions._handleChange(newValue);
        //if(sourceMapFn) && 
    }),
    _handleChange: action((state,newValue)=>{
        state.value = newValue;
    })
 */
