import { TextField, TextFieldProps } from "@mui/material"
import { createLocalHooks } from "core/store"
import type { ModelBasePure, ToStoreType } from "easy-peasy-classes"
import React, { ReactDOM, useMemo, useState } from "react"
import MaskedInput from "react-text-mask"

import type { commonTextFieldModel } from "./textField.model"

export type TextField$Props = {
    mapper: (s: any) => any
    inputMask?: Array<string | RegExp>
} & TextFieldProps

type test = "RegExp" extends ModelBasePure ? true : false
function unpack<T>(x: T): T extends ToStoreType<infer U> ? U : T {
    //TODO: generalise unpack logic
    return x as any
}
export function TextField$(props: TextField$Props) {
    const { mapper: µ, ...rest } = props

    const { useLocalState, useLocalActions } = createLocalHooks<commonTextFieldModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    const mask = rest.inputMask || unitState?.inputMask
    const InputComponent = useMemo(() => TextMaskCustomFn(mask as any), [mask])

    if (!unitState) return null

    if (!mask) {
        return (
            <TextField
                value={unitState.value}
                label={unitState.label}
                onChange={(e) => unitActions.handleChange(e.target.value)}
                helperText={unitState.helperText}
                error={unitState.isError}
                disabled={unitState.isDisabled}
                {...rest}
                InputLabelProps={{ shrink: !!unitState.value }} //no effect
            />
        )
    } else {
        return (
            <TextField
                value={unitState.value}
                label={unitState.label}
                onChange={(e) => unitActions.handleChange(e.target.value)}
                helperText={unitState.helperText}
                error={unitState.isError}
                disabled={unitState.isDisabled}
                InputLabelProps={{ shrink: !!unitState.value }}
                InputProps={{
                    inputComponent: React.forwardRef((props, ref) => <InputComponent inputRef={ref} mask={mask} {...props} />),
                }}
                {...rest}
            />
        )
    }
}

function TextMaskCustomFn(mask: Array<string | RegExp>) {
    const TextMaskCustom = ({ inputRef, ...rest }: { inputRef: any; mask: Array<string | RegExp> }) => {
        return (
            <MaskedInput
                {...rest}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={mask}
                placeholderChar={"\u2000"}
                //showMask
            />
        )
    }

    return TextMaskCustom
}
