import { EzModel } from "core/store/base"
import { Action, Model, Property } from "easy-peasy-classes"

@Model
export class commonDateFieldModel extends EzModel {
    @Property
    value: string = null

    @Property
    isDisabled: boolean = false

    @Property
    isError: boolean = false

    @Property
    helperText: string = ""

    // @Property
    // inputMask:string = ''

    @Property
    label: string = ""

    @Property
    // eslint-disable-next-line no-self-compare
    verifyFn = (value: string) => value === value

    @Action
    setError(error: string) {
        if (!error) {
            this.isError = false
            this.helperText = ""
        } else {
            this.isError = true
            this.helperText = error
        }
    }

    @Action
    verify() {
        this.isError = this.verifyFn(this.value)
    }

    @Action
    handleChange(newValue: string) {
        this.value = newValue
    }

    @Action
    setValue(newValue: string) {
        this.value = newValue
    }
}
