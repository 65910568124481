import { styled } from "@mui/material"
//import { CopyToClipboard } from 'react-copy-to-clipboard';
//import { Unlayer } from 'core/shared/misc';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material/"
import * as colors from "@mui/material/colors"
import { ComboBox$, TextField$ } from "core/controls"
import { useEzActions, useEzState, useMapper } from "core/store"
import React, { useEffect } from "react"

export function NewEmail() {
    const µ = useMapper((s) => s.core.dialogs.newEmail)
    const unitActions = useEzActions((s) => s.core.dialogs.newEmail)
    const isOpen = useEzState((s) => µ(s).dialog.isOpen)
    const unlayerState = useEzState((s) => s.core.dialogs.newEmail.unlayer)

    //const emailEditorRef = useRef(null);

    const exportHtml = () => {
        window.unlayer.exportHtml((data: { html: any; design: any }) => {
            const { html, design } = data

            if (html) unitActions.validate({ html, design }) //else wait for unlayer to load
        })
    }

    const copyTemplateToClipBoard = () => {
        window.unlayer.exportHtml((data: { design: any }) => {
            const { design } = data
            const newDesign = JSON.stringify(design)
            navigator.clipboard ? navigator.clipboard.writeText(newDesign) : console.log(newDesign)
        })
    }

    const design = unlayerState.design
    if (!isOpen) return null

    return (
        <Dialog open={isOpen} sx={{ backgroundColor: colors.grey[50], minHeight: "70vh" }} fullWidth maxWidth="xl">
            <DialogTitle>Envoi de mail</DialogTitle>

            <DialogContent style={{ minHeight: "80vh", display: "flex", flexDirection: "column", flex: 1 }}>
                <Grid container style={{ display: "flex", flexDirection: "column", flex: 1, marginTop: "20px" }}>
                    <Grid item xs={12} container spacing={3} justifyContent="flex-start">
                        <Grid item xs>
                            <ComboBox$ id="lstSender$" mapper={(s) => µ(s).lstSender$} label="Expéditeur" freeSolo />
                        </Grid>
                        <Grid item xs>
                            <ComboBox$ id="lstRecipient$" mapper={(s) => µ(s).lstRecipient$} label="Destinataire" freeSolo />
                        </Grid>
                        <Grid item xs>
                            <TextField$ id="txtSubject$" mapper={(s) => µ(s).txtSubject$} label="Objet" fullWidth size="small" />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <EmailEditor design={design} />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={copyTemplateToClipBoard}>Copier template</Button>

                <Button onClick={() => unitActions.cancel()}>Annuler</Button>
                <Button onClick={exportHtml} color="primary" autoFocus>
                    Envoyer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const UnlayerDiv = styled("div")({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& iframe": {
        flex: 1,
        display: "flex",
    },
})

function EmailEditor(props: { design: any; projectId?: number }) {
    const { design } = props
    const { unlayer } = window

    const [isInitialized, setInitialized] = React.useState(false)

    const initializeUnlayer = () => {
        if (!isInitialized) {
            unlayer.init({
                id: "unlayer",
                projectId: props.projectId ?? 0,
                displayMode: "email",
            })
            setInitialized(true)
        }

        unlayer.loadDesign(design)
    }

    useEffect(() => {
        initializeUnlayer()
    }, [design])

    return <UnlayerDiv id="unlayer" />
}
