import TravelExploreIcon from "@mui/icons-material/TravelExplore"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { ApiAdresse, ApiAdresseRawResultItem, ApiAdresseSearchResult } from "core/services/apiAdresse"
import { debounce } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"

const renderAdresse = (item, isHighlighted: boolean) => {
    const { id, label, context, type } = item
    return (
        <div key={id} style={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "center", padding: "1em", ...(isHighlighted && { backgroundColor: "lightseagreen", color: "white" }) }}>
            <div>
                <div className="item-label">{label}</div>
                <div>{context}</div>
            </div>
        </div>
    )
}

interface SearchLocationInputProps {
    isManual?: boolean
    defaultValue?: string
    onSelect?: (query: ApiAdresseSearchResult) => any
    autocomplete?: boolean
    type: "housenumber" | "street" | "locality" | "municipality"
    label?: string
}
export function ApiAdresseSearchInput({ isManual = false, onSelect, autocomplete = true, type, defaultValue = "", label = "Rechercher" }: SearchLocationInputProps) {
    const [inputText, setInputText] = useState(defaultValue)

    // useEffect(()=>{
    //     setInputText(inputText => inputText || defaultValue)
    // },[defaultValue])

    // const [url, setUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState<ApiAdresseRawResultItem[]>([])
    const [error, setError] = useState(null)

    const fetchApi = useCallback(
        debounce(async (input) => {
            setIsLoading(true)
            setError(null)

            try {
                const updatedList = await ApiAdresse.search({
                    query: input,
                    type,
                    autocomplete: autocomplete,
                })
                setList(updatedList)
            } catch (err) {
                setError(err)
                console.log(err)
            }

            setIsLoading(false)
        }, 300),
        [type, autocomplete]
    )

    useEffect(() => {
        if (inputText) {
            fetchApi(inputText)
        }
    }, [fetchApi, inputText])

    const handleSelect = useCallback(
        (result: ApiAdresseRawResultItem) => {
            const cleanResult = ApiAdresse.cleanResult(result)

            onSelect && onSelect(cleanResult)
        },
        [onSelect]
    )

    return (
        <Autocomplete
            size="small"
            filterOptions={(x) => x}
            getOptionLabel={(option: ApiAdresseRawResultItem) => option.label}
            options={list}
            loading={isLoading}
            onChange={(e, selected) => {
                typeof selected !== "string" && selected && handleSelect(selected)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    autoComplete="random-xxxx"
                    InputProps={{
                        onChange: (e) => {
                            fetchApi(e.target.value)
                        },
                        defaultValue: defaultValue,
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : <TravelExploreIcon />}
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}
