import { Slider, SliderProps, Typography } from "@mui/material"
import { useField, useFormikContext } from "formik"
import React from "react"

import { RawSlider, RawSliderProps } from "../rawFields/RawSlider.com"

export interface FmkSliderProps extends Omit<RawSliderProps, "value" | "error" | "onChange"> {
    name: string
    helperText?: string
    validateImmediately?: boolean
}
export function FmkSlider(props: FmkSliderProps) {
    let { name, helperText, validateImmediately, ...otherProps } = props
    const [field, meta, { setValue, setTouched }] = useField(name)
    const { setFieldValue, setFieldTouched } = useFormikContext()

    helperText = meta.error ?? helperText

    const handleChange = (newValue: any, optionData: any) => {
        setTouched(true, false)
        setValue(newValue, true)
    }

    const defaultProps: Partial<RawSliderProps> = {
        onChange: handleChange,
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }

    if (meta && (meta.touched || validateImmediately) && meta.error) {
        config.error = true
        config.helperText = meta.error
    }

    const { grid, ...propsWithoutGrid } = config

    return (
        <React.Fragment>
            <RawSlider {...propsWithoutGrid} grid={grid as any} />
        </React.Fragment>
    )
}

export default FmkSlider
