import { usePrevious } from "core/hooks/usePrevious"
import { useEffect, useRef, useState } from "react"
// import { usePrevious } from "react-use"

export function useStateWithDeps<T>(initialValue: T, deps?: React.DependencyList) {
    deps = deps ?? [initialValue]

    const [state, setState] = useState<T>(initialValue)

    useEffect(() => {
        setState(initialValue)
    }, [deps])

    return [state, setState] as const
}

//Semble créer des bugs

export function useStateReinitialise<T>(initialValue: T, diff: (current: T, previous: T) => boolean = (current, previous) => previous !== current) {
    const [state, setState] = useState<T>(initialValue)

    const previous = usePrevious(initialValue)
    const vals = { previous, initialValue, hasChanged: (previous === undefined && initialValue !== undefined) || (previous !== undefined && diff(initialValue, previous)) }
    const ref = useRef(vals)
    ref.current = vals

    useEffect(() => {
        if (ref.current.hasChanged) setState(ref.current.initialValue)
    })

    return [state, setState] as const
}
