import { Box, BoxProps, Typography, TypographyProps } from "@mui/material"
import { forwardRef, ReactNode } from "react"

export interface LabelledTextProps {
    label: string
    children: ReactNode
    _label?: TypographyProps
    _text?: TypographyProps
    _container?: BoxProps
}

export const LabelledText = forwardRef(function LabelledText(props: LabelledTextProps, ref) {
    const { _label, _text, _container, children, label } = props

    return (
        <Box {..._container} ref={ref}>
            <Typography variant="subtitle2" color="GrayText" {..._label} component={"div" as any}>
                {label}
            </Typography>
            <Typography {..._text} component={"div" as any}>
                {children}
            </Typography>
        </Box>
    )
})
