import { Checkbox, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material"
import { createLocalHooks } from "core/store"

import type { commonCheckBoxListModel } from "./checkBoxList.model"

export interface CheckBoxList$Props {
    id?: string
    mapper: (s: any) => any
}
export function CheckBoxList$(props: CheckBoxList$Props) {
    //

    const { mapper: µ } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonCheckBoxListModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    return (
        <List>
            {unitState.rows.map((row, index) => {
                //const labelId = `checkbox-list-label-${value}`;

                return (
                    <ListItem key={index} role={undefined} dense button onClick={() => unitActions.handleToggle(index)}>
                        <ListItemIcon>
                            <Checkbox edge="start" checked={row.isChecked} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={row.primary} secondary={row.secondary} />
                        <ListItemSecondaryAction />
                    </ListItem>
                )
            })}
        </List>
    )
}
