import { useCallback, useState } from "react"

export function usePopover<N extends string = "Popover">(
    popoverName?: N
): { [K in `anchorEl${N}`]: EventTarget & HTMLElement } & { [K in `anchorPosition${N}`]: { top: number; left: number } } & { [K in `open${N}`]: (anchorEl: EventTarget & HTMLElement) => void } & { [K in `close${N}`]: () => void } & { [K in `is${N}Open`]: boolean } {
    popoverName = popoverName ?? ("Popover" as any)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [coordinates, setCoordinates] = useState<{ x: number; y: number }>({ x: null, y: null })

    const open = useCallback((anchorEl: EventTarget & HTMLElement) => {
        setAnchorEl(anchorEl)
        const { x, y } = anchorEl.getBoundingClientRect()
        setCoordinates({ x, y })
    }, [])
    const close = useCallback(() => {
        setAnchorEl(null)
    }, [])
    return {
        [`anchorEl${popoverName}`]: anchorEl,
        [`anchorPosition${popoverName}`]: { top: coordinates.y, left: coordinates.x },
        [`is${popoverName}Open`]: Boolean(anchorEl),
        [`open${popoverName}`]: open,
        [`close${popoverName}`]: close,
    } as any
}
